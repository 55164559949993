import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/hero.png";
import thumbnailEvent from "../../static/images/feature-event.png"
import thumbnailBoard from "../../static/images/feature-board.png"
import thumbnailNews from "../../static/images/feature-news.png"
import thumbnailTeams from "../../static/images/feature-team.png"
import thumbnailStaff from "../../static/images/feature-user.png"

const IndexPage = () => (
    <Layout>
        <SEO title="OneClickRetargeting | Automate retareting"/>

        <div className={"page-header home"}>
            <h1>Launch retargeting ads in seconds instead of hours.</h1>
            <p>launch retargeting campaigns on Facebook, google and snapchat <br/> with automatically generated audiences and creatives - with a click of a button</p>
            {/* <img alt={"Dashboard"} src={featureImage}/> */}
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                {/* <img alt={"Event"} src={thumbnailEvent}/> */}
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Launch, fast!  Like, really fast.</h2>
                                <p>it should take seconds to launch retargeting campaigns, that includes audience, creatives, adsets, adgroups, etc...</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Don't leave money on the table</h2>
                                <p>Multiplatform retargeting is an easy way to make extra money, we know its hard to get a grip on multiple platforms, so we simplfied it for you!</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                {/* <img alt={"Board"} src={thumbnailBoard}/> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                {/* <img alt={"News"} src={thumbnailNews}/> */}
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Launch Facebook, instagram, google and snapchat at the same time. </h2>
                                <p>Using multiple platforms is complicated, OCR lets you launch on a centralized and user friendly platform</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                {/* <img alt={"Team"} src={thumbnailTeams}/> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                {/* <img alt={"Users"} src={thumbnailStaff}/> */}
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>E-commerce Ingerations</h2>
                                <p>Shopify integrations allows you to import product data to generate creatives </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    {/* <h2>Sign up for free</h2> */}
                    {/* <p>Sign up and start increasing the productivity of your business with OCR.</p> */}
                </div>

                <div className={"button"}>
                    <a href="" target={"_blank"}>Get Started</a>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
